/* styles.css */
.fasta-container {
    position: relative;
    padding: 2ch;
    background-color: #fcfffd;
    border-color: #557b74;
    border-style: dashed;
    line-height: 0.925em;
    /*margin: auto; !* Center the container *!*/
}
.styled-span {
    display: flex;
    align-items: center;
    text-align: center;
}
.vertical-line {
    flex-grow: 1;
    height: 20px;
    background-color: black;
}
.horizontal-line {
    width: 57px;
    height: 3px;
    background-color: black;
    margin: 0 0px;
}
code.fasta {
    white-space: pre-wrap;
    color: #595959;
}
.header {
    color: black;
}
.upstream {
    color: #80a0a0;
}
.downstream {
    color: #80a0a0;
}
.intron {
    color: #aaaaaa;
}
.utr5 {
    background-color: #aaccaf;
}
.utr5-other {
    background-color: #cfe1d2;
}
.cds {
    background-color: #a7b4d3;
}
.cds-other {
    background-color: #d0d8e6;
}
.utr3 {
    background-color: #c5a3bf;
}
.utr3-other {
    background-color: #dcc9d9;
}
.square-utr5 {
    width: 20px;
    height: 20px;
    background: linear-gradient(to bottom right, #aaccaf 0%, #aaccaf 50%, #cfe1d2 50%, #cfe1d2 100%);
}
.square-cds {
    width: 20px;
    height: 20px;
    background: linear-gradient(to bottom right, #a7b4d3 0%, #a7b4d3 50%, #d0d8e6 50%, #d0d8e6 100%);
}
.square-utr3 {
    width: 20px;
    height: 20px;
    background: linear-gradient(to bottom right, #c5a3bf 0%, #c5a3bf 50%, #dcc9d9 50%, #dcc9d9 100%);
}

.reverse-slide {
    direction: rtl;
}
.fasta-key {
    position: absolute;
    top: 9px;
    right: 40px;
    background: none;
    border: none;
    cursor: default;
    font-family: monospace;
    font-size: 11pt;
    color: #555;
}
.copy-button {
    position: absolute;
    top: 3px;
    right: -5px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: #555;
}
.notification {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #050505;
    color: #fff;
    padding: 5px;
    z-index: 999;
    font-weight: bold;
    font-style: italic;
    font-size: smaller;
}