.gramene-sidebar {
  background-color: #557b74;
  width: 250px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  position: fixed;
}

.sorghumbase-sidebar {
  background-color: #a03e34;
  width: 250px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  position: fixed;
}

.gramene-auth-container, .sorghumbase-auth-container, .gramene-filter-container, .sorghumbase-filter-container {
  background-color: #fff3a9;
  padding-bottom: 4px;
  padding-left: 4px;
}

.gramene-filter {
  background-color: #fff;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: small;
}

.gramene-filter span:hover {
  font-weight: bolder;
}

.gramene-filter-AND {
  background-color: #ffcd82;
}

.gramene-filter-OR {
  background-color: #c5eeb9;
}

.gramene-filter-NOT {
  border-left: 4px inset #ff5354;
  padding-left: 4px;
}

.gramene-filter-operation {
  text-align: center;
  font-style: italic;
}

.gramene-filter-marked {
  font-weight: bolder;
}

.gramene-filter-menu ul {
  border-top-style: solid;
  border-top-width: 2px;
  padding-left: 0;
}

.gramene-filter-menu li {
  cursor: pointer;
  padding-left: 15px;
  display: block;
}

.gramene-filter-menu li:hover {
  background-color: #feff96;
}

.gramene-view-header {
  color: #a03e34;
  background-color: #fff3a96e;
  border-top: solid #557b74;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  width: 100%;
  font-size: larger;
  font-weight: bold;
  display: flex;
}

.gramene-view-container {
  background-color: #fff3a9;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
}

.gramene-view {
  background-color: #fff;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 10px;
}

.gramene-view-span-on {
  cursor: pointer;
  vertical-align: text-bottom;
  color: #a03e34;
  padding-left: 5px;
}

.gramene-view-span-off {
  cursor: pointer;
  vertical-align: text-bottom;
  color: #a9a9a9;
  padding-left: 5px;
}

.gramene-view li {
  cursor: pointer;
  border-left-style: inset;
  border-left-width: 8px;
  margin-bottom: 2px;
  padding-left: 5px;
  display: block;
}

.gramene-view-on {
  border-left-color: #40a0ff;
  font-weight: bold;
}

.gramene-view-off {
  color: gray;
  border-left-color: #ff6d68;
}

.gramene-view-disabled {
  color: #a9a9a9;
  border-left-color: #efefef;
  cursor: default !important;
}

#gramene-suggestion button {
  border-radius: .6rem;
  line-height: 1;
}

#gramene-suggestion button:focus {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.visible-detail {
  border: 1px solid #557b74;
  padding: 6px 12px;
}

.detail {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.table {
  width: 100%;
  max-width: 100%;
}

.xrefs {
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  border-color: gray;
}

.xref-name-col {
  width: 20%;
}

.xref-value-col, .xref-80-col {
  width: 80%;
}

.xref-70-col {
  width: 70%;
}

.xref-10-col {
  width: 10%;
}

.xref-id-list {
  padding-left: 0;
  list-style: none;
}

.result-gene {
  border: 5px solid #fff;
  padding: .2em;
}

.result-gene:hover {
  border-color: #faebd7;
}

.gene-id {
  color: #000;
}

.gene-name {
  color: #557b74;
  margin-bottom: 0;
}

.gene-description {
  margin-bottom: 0;
}

.gene-species {
  white-space: nowrap;
  font-size: small;
  font-style: italic;
  font-weight: bold;
}

.gene-synonyms {
  font-size: small;
}

.gene-extras {
  float: right;
  border: 1px dashed #00f;
}

.gene-panlink {
  float: right;
}

.gene-panlink img {
  vertical-align: top;
  width: auto;
  height: 60px;
}

.gene-curation {
  float: right;
  border: 1px solid gray;
  margin-top: 20px;
}

.gene-curation span {
  color: #fff;
  text-align: center;
  width: 20px;
  display: inline-block;
}

.gene-curation .okay {
  background-color: #0c00f3;
}

.gene-curation .flagged {
  background-color: #ff8c00;
}

.gene-detail-tabs {
  white-space: nowrap;
  flex-wrap: wrap;
  width: 80em;
  display: flex;
}

.gene-detail-tab-closed {
  color: #006400;
  cursor: pointer;
}

.gene-detail-tab-disabled {
  color: #d3d3d3;
  cursor: not-allowed;
}

.gene-detail-tab-empty {
  color: #006400;
  cursor: pointer;
  font-style: oblique;
}

.gene-detail-tab-empty:before {
  content: "Add ";
}

.gene-detail-tab-expanded {
  color: #fff;
  cursor: pointer;
  background-color: #557b74;
}

.result-gene-summary {
  flex-flow: row;
  justify-content: flex-start;
  display: flex;
}

.result-gene-summary .species-name {
  white-space: nowrap;
  font-style: italic;
}

.result-gene-title-body {
  flex: auto;
  width: 1036px;
}

.model-ortholog, .closest-ortholog, .gene-summary-tair {
  cursor: pointer;
  border: 1px solid #ff8c00;
  flex: auto;
  width: 500px;
  margin-top: 10px;
  margin-left: 1rem;
  padding: 0 1rem;
  font-size: smaller;
}

.model-ortholog {
  border: 1px dotted #ff8c00;
}

.closest-ortholog {
  border: 1px dotted #006400;
}

.gene-summary-tair {
  border: 1px dotted #00008b;
}

.gene-summary-tair .rest {
  display: none;
}

.gene-summary-tair:hover .rest, .gene-summary-tair:focus .rest {
  display: inline;
}

.gene-summary-tair:hover .ellipsis, .gene-summary-tair:focus .ellipsis {
  display: none;
}

.closest-ortholog:before, .model-ortholog:before, .gene-summary-tair:before {
  float: right;
  background: #fff;
  border: 1px dotted;
  margin-top: -12px;
  margin-right: 6px;
  padding: 0 1rem;
  position: relative;
}

.model-ortholog:before {
  content: "Model Species Homolog";
  color: orange;
}

.closest-ortholog:before {
  content: "Closest Annotated Homolog";
  color: #006400;
}

.gene-summary-tair:before {
  content: "TAIR Curated Description";
  color: #00008b;
}

.rep-identity {
  float: right;
  padding-top: 5px;
  padding-right: 10px;
  font-style: italic;
}

.rep-identity:after {
  content: "% identity";
}

.eFP:before {
  content: url("BAR-logo.fb7d0f73.png");
  padding-right: 5px;
  position: relative;
  top: 5px;
}

.eFP:after {
  content: "New!";
  color: red;
  vertical-align: super;
  padding-left: 2px;
  font-size: x-small;
  font-style: italic;
  font-weight: bold;
  line-height: normal;
}

.gxa:before {
  content: url("expression-atlas-logo.87d25571.png");
  padding-right: 5px;
  position: relative;
  top: 5px;
}

.gene-search-pic-sugg {
  background: url("suggestions.7b2830b6.png") 0 0 / contain no-repeat;
  width: 100%;
  height: 200px;
}

.gene-search-pic-results {
  background: url("results.e437a98f.png") 0 0 / contain no-repeat;
  width: 100%;
  height: 200px;
}

.gene-search-pic-genetree {
  background: url("genetree.145467e3.png") 0 0 / contain no-repeat;
  width: 100%;
  height: 200px;
}

.MSAlignments-wrapper {
  overflow: scroll hidden;
}

.clustal {
  white-space: nowrap;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.clustal .A, .clustal .I, .clustal .L, .clustal .M, .clustal .F, .clustal .W, .clustal .V {
  background-color: #80a0f0;
}

.clustal .R, .clustal .K {
  color: #fcff89;
  background-color: #f01505;
}

.clustal .N, .clustal .Q, .clustal .S, .clustal .T {
  background-color: #0f0;
}

.clustal .D, .clustal .E {
  color: #fcff89;
  background-color: #c048c0;
}

.clustal .C {
  background-color: #f08080;
}

.clustal .G {
  background-color: #f09048;
}

.clustal .Y .clustal .H {
  background-color: #15a4a4;
}

.clustal .P {
  background-color: #ff0;
}

.clustal .B, .clustal .X, .clustal .Z {
  background-color: #fff;
}

.clustal .gap {
  color: #ccc;
}

.zappo {
  white-space: nowrap;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.zappo .A, .zappo .I, .zappo .L, .zappo .M, .zappo .V {
  background-color: #ffafaf;
}

.zappo .R, .zappo .H, .zappo .K {
  color: #fcff89;
  background-color: #6464ff;
}

.zappo .N, .zappo .Q, .zappo .S, .zappo .T {
  background-color: #0f0;
}

.zappo .D, .zappo .E {
  background-color: red;
}

.zappo .C {
  background-color: #ff0;
}

.zappo .G, .zappo .P {
  color: #fcff89;
  background-color: #f0f;
}

.zappo .F, .zappo .W, .zappo .Y {
  background-color: #ffc800;
}

.zappo .B, .zappo .X, .zappo .Z {
  background-color: #fff;
}

.zappo .gap {
  color: #ccc;
}

.taylor {
  white-space: nowrap;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.taylor .A {
  background-color: #cf0;
}

.taylor .R {
  color: #fcff89;
  background-color: #00f;
}

.taylor .N {
  color: #fcff89;
  background-color: #c0f;
}

.taylor .D {
  color: #fcff89;
  background-color: red;
}

.taylor .C {
  background-color: #ff0;
}

.taylor .Q {
  background-color: #f0c;
}

.taylor .E {
  color: #fcff89;
  background-color: #f06;
}

.taylor .G {
  background-color: #f90;
}

.taylor .H {
  color: #fcff89;
  background-color: #06f;
}

.taylor .I {
  background-color: #6f0;
}

.taylor .L {
  background-color: #3f0;
}

.taylor .K {
  color: #fcff89;
  background-color: #60f;
}

.taylor .M {
  background-color: #0f0;
}

.taylor .F {
  background-color: #0f6;
}

.taylor .P {
  background-color: #fc0;
}

.taylor .S {
  color: #fcff89;
  background-color: #f30;
}

.taylor .T {
  background-color: #f60;
}

.taylor .W {
  background-color: #0cf;
}

.taylor .Y {
  background-color: #0fc;
}

.taylor .V {
  background-color: #9f0;
}

.taylor .B, .taylor .X, .taylor .Z {
  background-color: #fff;
}

.taylor .gap {
  color: #ccc;
}

.hydrophobicity {
  white-space: nowrap;
  color: #fcff89;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.hydrophobicity .A {
  background-color: #ad0052;
}

.hydrophobicity .R, .hydrophobicity .K {
  background-color: #00f;
}

.hydrophobicity .N, .hydrophobicity .D, .hydrophobicity .Q, .hydrophobicity .E, .hydrophobicity .B, .hydrophobicity .Z {
  background-color: #0c00f3;
}

.hydrophobicity .C {
  background-color: #c2003d;
}

.hydrophobicity .G {
  background-color: #6a0095;
}

.hydrophobicity .H {
  background-color: #1500ea;
}

.hydrophobicity .L {
  background-color: #ea0015;
}

.hydrophobicity .M {
  background-color: #b0004f;
}

.hydrophobicity .F {
  background-color: #cb0034;
}

.hydrophobicity .P {
  background-color: #4600b9;
}

.hydrophobicity .S {
  background-color: #5e00a1;
}

.hydrophobicity .T {
  background-color: #61009e;
}

.hydrophobicity .W {
  background-color: #5b00a4;
}

.hydrophobicity .Y {
  background-color: #4f00b0;
}

.hydrophobicity .V {
  background-color: #f60009;
}

.hydrophobicity .X {
  background-color: #680097;
}

.hydrophobicity .gap {
  color: #ccc;
}

.helix_propensity {
  white-space: nowrap;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.helix_propensity .A {
  background-color: #e718e7;
}

.helix_propensity .R {
  background-color: #6f906f;
}

.helix_propensity .N {
  background-color: #1be41b;
}

.helix_propensity .D {
  background-color: #787;
}

.helix_propensity .C {
  background-color: #23dc23;
}

.helix_propensity .Q {
  background-color: #926d92;
}

.helix_propensity .E {
  background-color: #f0f;
}

.helix_propensity .G {
  background-color: #0f0;
}

.helix_propensity .H {
  background-color: #758a75;
}

.helix_propensity .I {
  background-color: #8a758a;
}

.helix_propensity .L {
  background-color: #ae51ae;
}

.helix_propensity .K {
  background-color: #a05fa0;
}

.helix_propensity .M {
  background-color: #ef10ef;
}

.helix_propensity .F {
  background-color: #986798;
}

.helix_propensity .P {
  background-color: #0f0;
}

.helix_propensity .S {
  background-color: #36c936;
}

.helix_propensity .T {
  background-color: #47b847;
}

.helix_propensity .W {
  background-color: #8a758a;
}

.helix_propensity .Y {
  background-color: #21de21;
}

.helix_propensity .V {
  background-color: #857a85;
}

.helix_propensity .B {
  background-color: #49b649;
}

.helix_propensity .X {
  background-color: #758a75;
}

.helix_propensity .Z {
  background-color: #c936c9;
}

.helix_propensity .gap {
  color: #ccc;
}

.strand_propensity {
  white-space: nowrap;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.strand_propensity .A {
  background-color: #5858a7;
}

.strand_propensity .R {
  background-color: #6b6b94;
}

.strand_propensity .N {
  background-color: #64649b;
}

.strand_propensity .D {
  background-color: #2121de;
}

.strand_propensity .C {
  background-color: #9d9d62;
}

.strand_propensity .Q {
  background-color: #8c8c73;
}

.strand_propensity .E {
  background-color: #00f;
}

.strand_propensity .G {
  background-color: #4949b6;
}

.strand_propensity .H {
  background-color: #60609f;
}

.strand_propensity .I {
  background-color: #ecec13;
}

.strand_propensity .L {
  background-color: #b2b24d;
}

.strand_propensity .K {
  background-color: #4747b8;
}

.strand_propensity .M {
  background-color: #82827d;
}

.strand_propensity .F {
  background-color: #c2c23d;
}

.strand_propensity .P {
  background-color: #2323dc;
}

.strand_propensity .S {
  background-color: #4949b6;
}

.strand_propensity .T {
  background-color: #9d9d62;
}

.strand_propensity .W {
  background-color: #c0c03f;
}

.strand_propensity .Y {
  background-color: #d3d32c;
}

.strand_propensity .V {
  background-color: #ff0;
}

.strand_propensity .B {
  background-color: #4343bc;
}

.strand_propensity .X {
  background-color: #797986;
}

.strand_propensity .Z {
  background-color: #4747b8;
}

.strand_propensity .A, .strand_propensity .R, .strand_propensity .N, .strand_propensity .D, .strand_propensity .E, .strand_propensity .G, .strand_propensity .H, .strand_propensity .K, .strand_propensity .P, .strand_propensity .S, .strand_propensity .B, .strand_propensity .Z {
  color: #fcff89;
}

.strand_propensity .gap {
  color: #ccc;
}

.turn_propensity {
  white-space: nowrap;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.turn_propensity .gap {
  color: #ccc;
}

.turn_propensity .A {
  background-color: #2cd3d3;
}

.turn_propensity .R {
  background-color: #708f8f;
}

.turn_propensity .N {
  background-color: red;
}

.turn_propensity .D {
  background-color: #e81717;
}

.turn_propensity .C {
  background-color: #a85757;
}

.turn_propensity .Q {
  background-color: #3fc0c0;
}

.turn_propensity .E {
  background-color: #788;
}

.turn_propensity .G {
  background-color: red;
}

.turn_propensity .H {
  background-color: #708f8f;
}

.turn_propensity .I {
  background-color: #0ff;
}

.turn_propensity .L {
  background-color: #1ce3e3;
}

.turn_propensity .K {
  background-color: #7e8181;
}

.turn_propensity .M, .turn_propensity .F {
  background-color: #1ee1e1;
}

.turn_propensity .P {
  background-color: #f60909;
}

.turn_propensity .S {
  background-color: #e11e1e;
}

.turn_propensity .T, .turn_propensity .W {
  background-color: #738c8c;
}

.turn_propensity .Y {
  background-color: #9d6262;
}

.turn_propensity .V {
  background-color: #07f8f8;
}

.turn_propensity .B {
  background-color: #f30c0c;
}

.turn_propensity .X {
  background-color: #7c8383;
}

.turn_propensity .Z {
  background-color: #5ba4a4;
}

.turn_propensity .N, .turn_propensity .D, .turn_propensity .G, .turn_propensity .P, .turn_propensity .S, .turn_propensity .B {
  color: #fcff89;
}

.buried_index {
  white-space: nowrap;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.buried_index .gap {
  color: #ccc;
}

.buried_index .A {
  background-color: #00a35c;
}

.buried_index .R {
  background-color: #00fc03;
}

.buried_index .N, .buried_index .D {
  background-color: #00eb14;
}

.buried_index .C {
  background-color: #00f;
}

.buried_index .Q, .buried_index .E {
  background-color: #00f10e;
}

.buried_index .G {
  background-color: #009d62;
}

.buried_index .H {
  background-color: #00d52a;
}

.buried_index .I {
  background-color: #0054ab;
}

.buried_index .L {
  background-color: #007b84;
}

.buried_index .K {
  background-color: #0f0;
}

.buried_index .M {
  background-color: #009768;
}

.buried_index .F {
  background-color: #008778;
}

.buried_index .P {
  background-color: #00e01f;
}

.buried_index .S {
  background-color: #00d52a;
}

.buried_index .T {
  background-color: #00db24;
}

.buried_index .W {
  background-color: #00a857;
}

.buried_index .Y {
  background-color: #00e619;
}

.buried_index .V {
  background-color: #005fa0;
}

.buried_index .B {
  background-color: #00eb14;
}

.buried_index .X {
  background-color: #00b649;
}

.buried_index .Z {
  background-color: #00f10e;
}

.buried_index .C, .buried_index .I, .buried_index .V {
  color: #fcff89;
}

.display-mode .btn-toolbar {
  padding-left: 10px;
  position: absolute;
}

.tooltip-inner {
  max-width: 400px;
}

.genetree-vis .node, .genetree-vis .edge {
  cursor: pointer;
}

.genetree-vis .tree-wrapper * {
  transition: all 20ms ease-in-out;
}

.genetree-vis .tree-wrapper .clade {
  transition: transform 20ms ease-in-out;
}

.genetree-vis .tree-wrapper .clade .edge-rect {
  transition: scaleY 20ms ease-in-out, scaleX 20ms ease-in-out, transform 20ms ease-in-out;
}

.genetree-vis .interaction-helper {
  opacity: 0;
}

.genetree-vis .node circle {
  stroke: #777;
  fill: #fff;
}

.genetree-vis .speciation rect {
  fill: navy;
}

.genetree-vis .duplication rect {
  fill: #cd0000;
}

.genetree-vis .node:hover .internal, .genetree-vis .edge:hover .internal, .genetree-vis .node:hover .gene circle, .genetree-vis .edge:hover .gene circle, .genetree-vis .node:hover ~ .clade .node rect, .genetree-vis .edge:hover ~ .clade .node rect, .genetree-vis .node:hover + .node rect, .genetree-vis .edge:hover + .node rect {
  transform: scale(2);
}

.genetree-vis .node:hover ~ .clade .interaction-rect, .genetree-vis .edge:hover ~ .clade .interaction-rect {
  opacity: 1;
  fill-opacity: 1;
}

.genetree-vis .node:hover ~ .clade circle, .genetree-vis .edge:hover ~ .clade circle, .genetree-vis .node:hover + .node circle, .genetree-vis .edge:hover + .node circle, .genetree-vis .node:hover .collapsed path, .genetree-vis .edge:hover .collapsed path, .genetree-vis .node:hover ~ .clade .collapsed path, .genetree-vis .edge:hover ~ .clade .collapsed path, .genetree-vis .node:hover + .node .collapsed path, .genetree-vis .edge:hover + .node .collapsed path {
  stroke-width: 2px;
}

.genetree-vis .node:hover ~ .clade text, .genetree-vis .edge:hover ~ .clade text, .genetree-vis .node:hover text, .genetree-vis .edge:hover text {
  font-weight: bolder;
}

.genetree-vis .node .gene.ortholog_one2one circle, .genetree-vis .node .gene.ortholog_one2many circle, .genetree-vis .node .gene.ortholog_many2many circle {
  stroke: orange;
}

.genetree-vis .node .gene.within_species_paralog circle {
  stroke: green;
}

.genetree-vis .node .gene.self circle {
  stroke: red;
  fill: red;
}

.genetree-vis .node .gene.self text {
  font-weight: bolder;
}

.genetree-vis .node .gene.representative circle {
  stroke: orange;
  fill: orange;
}

.genetree-vis .node text {
  fill-opacity: 1;
  text-anchor: start;
  font-size: 11px;
}

.genetree-vis .collapsed path {
  stroke-width: 1px;
}

.genetree-vis .domains rect {
  cursor: pointer;
  stroke-opacity: 0;
}

.genetree-vis .domains rect:hover {
  cursor: pointer;
  stroke-opacity: 1;
}

.popover-title .tooltip-title-button {
  margin-top: -4px !important;
  margin-right: 4px !important;
}

.msa-slider {
  margin-bottom: -10px;
  padding-top: 10px;
  padding-bottom: 0;
  position: relative;
}

.resize-drag {
  color: red;
  box-sizing: border-box;
  background: none;
  border: 4px solid #7c8383;
  border-radius: 8px;
  font-family: sans-serif;
  font-size: 1px;
}

.resize-container {
  width: 100%;
  height: 100%;
}

.rstm-toggle-icon {
  display: inline-block;
}

.rstm-toggle-icon-symbol {
  text-align: center;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
}

.rstm-tree-item-group {
  text-align: left;
  border-top: 1px solid #ccc;
  width: 100%;
  padding-left: 0;
  list-style-type: none;
}

.rstm-tree-item {
  cursor: pointer;
  color: #333;
  box-shadow: none;
  z-index: unset;
  background: none;
  border-bottom: 1px solid #ccc;
  padding: .75rem 1rem;
  position: relative;
}

.rstm-tree-item--active {
  color: #fff;
  background: #179ed3;
  border-bottom: none;
}

.rstm-tree-item--focused {
  z-index: 999;
  box-shadow: 0 0 5px #222;
}

.rstm-search {
  border: none;
  width: 100%;
  padding: 1rem 1.5rem;
}

.tree-view-node {
  margin-left: 10px;
}

.tree-view-node-collapse-toggle.collapse-spacer {
  width: 14px;
  display: inline-block;
}

.tree-view-node-collapse-toggle {
  width: 14px;
  margin-right: 3px;
}

.tree-view-node-label {
  border: 1px solid #fff;
  margin-left: 3px;
  padding: 1px;
  font-size: smaller;
}

.tree-view-node-label.selected {
  background-color: gray;
  border: 1px solid #003f81;
  border-radius: 3px;
}

.button-like-link {
  color: #007bff;
  cursor: pointer;
  background-color: #fff0;
  border: none;
  padding: 0;
  text-decoration: underline;
  display: inline-block;
}

.fasta-container {
  background-color: #fcfffd;
  border-style: dashed;
  border-color: #557b74;
  padding: 2ch;
  line-height: .925em;
  position: relative;
}

.styled-span {
  text-align: center;
  align-items: center;
  display: flex;
}

.vertical-line {
  background-color: #000;
  flex-grow: 1;
  height: 20px;
}

.horizontal-line {
  background-color: #000;
  width: 57px;
  height: 3px;
  margin: 0;
}

code.fasta {
  white-space: pre-wrap;
  color: #595959;
}

.header {
  color: #000;
}

.upstream, .downstream {
  color: #80a0a0;
}

.intron {
  color: #aaa;
}

.utr5 {
  background-color: #aaccaf;
}

.utr5-other {
  background-color: #cfe1d2;
}

.cds {
  background-color: #a7b4d3;
}

.cds-other {
  background-color: #d0d8e6;
}

.utr3 {
  background-color: #c5a3bf;
}

.utr3-other {
  background-color: #dcc9d9;
}

.square-utr5 {
  background: linear-gradient(to bottom right, #aaccaf 0% 50%, #cfe1d2 50% 100%);
  width: 20px;
  height: 20px;
}

.square-cds {
  background: linear-gradient(to bottom right, #a7b4d3 0% 50%, #d0d8e6 50% 100%);
  width: 20px;
  height: 20px;
}

.square-utr3 {
  background: linear-gradient(to bottom right, #c5a3bf 0% 50%, #dcc9d9 50% 100%);
  width: 20px;
  height: 20px;
}

.reverse-slide {
  direction: rtl;
}

.fasta-key {
  cursor: default;
  color: #555;
  background: none;
  border: none;
  font-family: monospace;
  font-size: 11pt;
  position: absolute;
  top: 9px;
  right: 40px;
}

.copy-button {
  cursor: pointer;
  color: #555;
  background: none;
  border: none;
  font-size: 1.5rem;
  position: absolute;
  top: 3px;
  right: -5px;
}

.notification {
  color: #fff;
  z-index: 999;
  background-color: #050505;
  padding: 5px;
  font-size: smaller;
  font-style: italic;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
}

.taxogenomic-vis .gramene-search-vis {
  -webkit-user-select: none;
  cursor: pointer;
}

.taxogenomic-vis .gramene-search-vis canvas {
  position: absolute;
}

.taxogenomic-vis .gramene-search-vis > * {
  display: inline;
}

.taxogenomic-vis .gramene-search-vis text {
  fill: gray;
  stroke: #557b74;
  stroke-opacity: 0;
  stroke-width: .5px;
  -webkit-user-select: none;
  user-select: none;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 12px;
}

.taxogenomic-vis .gramene-search-vis text::selection {
  background: none;
}

.taxogenomic-vis .gramene-search-vis .taxonomy * {
  transition: fill .2s ease-in-out, stroke-opacity .2s ease-in-out;
}

.taxogenomic-vis .gramene-search-vis .clade .results-count {
  font-size: 10px;
}

.taxogenomic-vis .gramene-search-vis .clade .node circle {
  stroke: #557b74;
  stroke-width: 1px;
  fill: #f6f6f6;
}

.taxogenomic-vis .gramene-search-vis .clade .edge rect {
  fill: #ccc;
}

.taxogenomic-vis .gramene-search-vis .clade .clade.highlight > .node > circle, .taxogenomic-vis .gramene-search-vis .clade .clade.highlight > .edge > rect {
  fill: #f0b866;
}

.taxogenomic-vis .gramene-search-vis .clade .clade.highlight > .node-label text {
  fill: #557b74;
  stroke-opacity: 1;
}

.taxogenomic-vis .gramene-search-vis .clade .node:hover > circle, .taxogenomic-vis .gramene-search-vis .clade .edge:hover > circle, .taxogenomic-vis .gramene-search-vis .clade .node:hover > rect, .taxogenomic-vis .gramene-search-vis .clade .edge:hover > rect, .taxogenomic-vis .gramene-search-vis .clade .node:hover ~ .node > circle, .taxogenomic-vis .gramene-search-vis .clade .edge:hover ~ .node > circle, .taxogenomic-vis .gramene-search-vis .clade .node:hover ~ .clade .edge > rect, .taxogenomic-vis .gramene-search-vis .clade .edge:hover ~ .clade .edge > rect, .taxogenomic-vis .gramene-search-vis .clade .node:hover ~ .clade .node > circle, .taxogenomic-vis .gramene-search-vis .clade .edge:hover ~ .clade .node > circle {
  fill: #f0b866;
}

.taxogenomic-vis .gramene-search-vis .clade .node:hover ~ .node-label text, .taxogenomic-vis .gramene-search-vis .clade .edge:hover ~ .node-label text, .taxogenomic-vis .gramene-search-vis .clade .node:hover ~ .clade text, .taxogenomic-vis .gramene-search-vis .clade .edge:hover ~ .clade text, .taxogenomic-vis .gramene-search-vis .clade .node-label:hover text {
  fill: #557b74;
  stroke-opacity: 1;
}

.taxogenomic-vis .gramene-search-vis .clade .bin, .taxogenomic-vis .gramene-search-vis .clade .full-region {
  stroke: #557b74;
  stroke-width: 0;
  shape-rendering: crispedges;
  transition: none;
}

.taxogenomic-vis .gramene-search-vis .clade .bin.selected {
  fill: #ea8e75;
}

.taxogenomic-vis .gramene-search-vis .clade .bin:hover {
  fill: #557b74;
}

.taxogenomic-vis .gramene-search-vis .clade .full-region.selected {
  stroke-width: 1px;
  stroke: #ea8e75;
}

.taxogenomic-vis .gramene-search-vis .clade .full-region:hover {
  stroke-width: 1px;
  stroke: #557b74;
}

.taxogenomic-vis .gramene-search-vis .clade .species-background {
  fill: none;
}

.taxogenomic-vis .gramene-search-vis .clade .node:hover ~ .species-background, .taxogenomic-vis .gramene-search-vis .clade .edge:hover ~ .species-background, .taxogenomic-vis .gramene-search-vis .clade .node:hover ~ .clade .species-background, .taxogenomic-vis .gramene-search-vis .clade .edge:hover ~ .clade .species-background, .taxogenomic-vis .gramene-search-vis .clade .clade.highlight > .species-background {
  fill: #f9e3c3;
}

.accordion-button {
  padding: .4rem !important;
}

.accordion-button:hover {
  background-color: #dbe8f3;
}
