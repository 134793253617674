/* see src/components/Edge.js#HOVER_SCALE_FACTOR (currently line 8) */
.display-mode .btn-toolbar {
  position: absolute;
}
.genetree-vis {
  /* see src/components/Edge.js#HOVER_SCALE_FACTOR (currently line 8) */
}
.genetree-vis .node,
.genetree-vis .edge {
  cursor: pointer;
}
.genetree-vis .tree-wrapper {
  /* transitions for mouse hover, etc, interactions */
  /* transitions for expanding/contracting clades */
}
.genetree-vis .tree-wrapper * {
  transition: all 20ms ease-in-out;
}
.genetree-vis .tree-wrapper .clade {
  transition: transform 20ms ease-in-out;
}
.genetree-vis .tree-wrapper .clade .edge-rect {
  transition: scaleY 20ms ease-in-out, scaleX 20ms ease-in-out, transform 20ms ease-in-out;
}
.genetree-vis .interaction-helper {
  opacity: 0;
}
.genetree-vis .node circle {
  stroke: #777;
  fill: #fff;
}
.genetree-vis .speciation rect {
  fill: #000080;
}
.genetree-vis .duplication rect {
  fill: #cd0000;
}
.genetree-vis .gene_split rect {
  fill: #ffff00;
  -webkit-animation: pulse linear .2s;
  -webkit-animation-iteration-count: 50;
  animation: pulse linear .2s;
  animation-iteration-count: 50;
}
@-webkit-keyframes pulse {
   0% { fill: #cd0000; }
   50% { fill: #ffff00; }
   100% { fill: #0000ff; }
 }
@keyframes pulse {
  0% { fill: #cd0000; }
  50% { fill: #ffff00; }
  100% { fill: #0000ff; }
}
.genetree-vis .node:hover .internal,
.genetree-vis .edge:hover .internal,
.genetree-vis .node:hover .gene circle,
.genetree-vis .edge:hover .gene circle,
.genetree-vis .node:hover ~ .clade .node rect,
.genetree-vis .edge:hover ~ .clade .node rect,
.genetree-vis .node:hover + .node rect,
.genetree-vis .edge:hover + .node rect {
  transform: scale(2);
}
.genetree-vis .node:hover ~ .clade .interaction-rect,
.genetree-vis .edge:hover ~ .clade .interaction-rect {
  opacity: 1;
  fill-opacity: 1;
}
.genetree-vis .node:hover ~ .clade circle,
.genetree-vis .edge:hover ~ .clade circle,
.genetree-vis .node:hover + .node circle,
.genetree-vis .edge:hover + .node circle,
.genetree-vis .node:hover .collapsed path,
.genetree-vis .edge:hover .collapsed path,
.genetree-vis .node:hover ~ .clade .collapsed path,
.genetree-vis .edge:hover ~ .clade .collapsed path,
.genetree-vis .node:hover + .node .collapsed path,
.genetree-vis .edge:hover + .node .collapsed path {
  stroke-width: 2;
}
.genetree-vis .node:hover ~ .clade text,
.genetree-vis .edge:hover ~ .clade text,
.genetree-vis .node:hover text,
.genetree-vis .edge:hover text {
  font-weight: bolder;
}
.genetree-vis .node .gene.ortholog_one2one circle,
.genetree-vis .node .gene.ortholog_one2many circle,
.genetree-vis .node .gene.ortholog_many2many circle {
  stroke: orange;
}
.genetree-vis .node .gene.within_species_paralog circle {
  stroke: green;
}
.genetree-vis .node .gene.self circle {
  stroke: red;
  fill: red;
}
.genetree-vis .node .gene.self text {
  font-weight: bolder;
}
.genetree-vis .node .gene.representative circle {
  stroke: orange;
  fill: orange;
}
.genetree-vis .node text {
  font-size: 11px;
  fill-opacity: 1;
  text-anchor: start;
}
.genetree-vis .collapsed path {
  stroke-width: 1;
}
.genetree-vis .domains rect {
  cursor: pointer;
  stroke-opacity: 0;
}
.genetree-vis .domains rect:hover {
  cursor: pointer;
  stroke-opacity: 1;
}
.genetree-vis .genetree-vis .node,
.genetree-vis .genetree-vis .edge {
  cursor: pointer;
}
.genetree-vis .genetree-vis .tree-wrapper {
  /* transitions for mouse hover, etc, interactions */
  /* transitions for expanding/contracting clades */
}
.genetree-vis .genetree-vis .tree-wrapper * {
  transition: all 20ms ease-in-out;
}
.genetree-vis .genetree-vis .tree-wrapper .clade {
  transition: transform 20ms ease-in-out;
}
.genetree-vis .genetree-vis .tree-wrapper .clade .edge-rect {
  transition: scaleY 20ms ease-in-out, scaleX 20ms ease-in-out, transform 20ms ease-in-out;
}
.genetree-vis .genetree-vis .interaction-helper {
  opacity: 0;
}
.genetree-vis .genetree-vis .node circle {
  stroke: #777;
  fill: #fff;
}
.genetree-vis .genetree-vis .speciation rect {
  fill: #000080;
}
.genetree-vis .genetree-vis .duplication rect {
  fill: #cd0000;
}
.genetree-vis .genetree-vis .node:hover .internal,
.genetree-vis .genetree-vis .edge:hover .internal,
.genetree-vis .genetree-vis .node:hover .gene circle,
.genetree-vis .genetree-vis .edge:hover .gene circle,
.genetree-vis .genetree-vis .node:hover ~ .clade .node rect,
.genetree-vis .genetree-vis .edge:hover ~ .clade .node rect,
.genetree-vis .genetree-vis .node:hover + .node rect,
.genetree-vis .genetree-vis .edge:hover + .node rect {
  transform: scale(2);
}
.genetree-vis .genetree-vis .node:hover ~ .clade .interaction-rect,
.genetree-vis .genetree-vis .edge:hover ~ .clade .interaction-rect {
  opacity: 1;
  fill-opacity: 1;
}
.genetree-vis .genetree-vis .node:hover ~ .clade circle,
.genetree-vis .genetree-vis .edge:hover ~ .clade circle,
.genetree-vis .genetree-vis .node:hover + .node circle,
.genetree-vis .genetree-vis .edge:hover + .node circle,
.genetree-vis .genetree-vis .node:hover .collapsed path,
.genetree-vis .genetree-vis .edge:hover .collapsed path,
.genetree-vis .genetree-vis .node:hover ~ .clade .collapsed path,
.genetree-vis .genetree-vis .edge:hover ~ .clade .collapsed path,
.genetree-vis .genetree-vis .node:hover + .node .collapsed path,
.genetree-vis .genetree-vis .edge:hover + .node .collapsed path {
  stroke-width: 2;
}
.genetree-vis .genetree-vis .node:hover ~ .clade text,
.genetree-vis .genetree-vis .edge:hover ~ .clade text,
.genetree-vis .genetree-vis .node:hover text,
.genetree-vis .genetree-vis .edge:hover text {
  font-weight: bolder;
}
.genetree-vis .genetree-vis .node .gene.ortholog_one2one circle,
.genetree-vis .genetree-vis .node .gene.ortholog_one2many circle,
.genetree-vis .genetree-vis .node .gene.ortholog_many2many circle {
  stroke: orange;
}
.genetree-vis .genetree-vis .node .gene.within_species_paralog circle {
  stroke: green;
}
.genetree-vis .genetree-vis .node .gene.self circle {
  stroke: red;
  fill: red;
}
.genetree-vis .genetree-vis .node .gene.self text {
  font-weight: bolder;
}
.genetree-vis .genetree-vis .node .gene.representative circle {
  stroke: orange;
  fill: orange;
}
.genetree-vis .genetree-vis .node text {
  font-size: 11px;
  fill-opacity: 1;
  text-anchor: start;
}
.genetree-vis .genetree-vis .collapsed path {
  stroke-width: 1;
}
.genetree-vis .genetree-vis .domains rect {
  cursor: pointer;
  stroke-opacity: 0;
}
.genetree-vis .genetree-vis .domains rect:hover {
  cursor: pointer;
  stroke-opacity: 1;
}
.genetree-vis .genetree-vis .tooltip-title-button {
  margin-top: -4px;
}
.popover-title .tooltip-title-button {
  margin-top: -4px !important;
  margin-right: 4px !important;
}
.msa-slider {
  position: relative;
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: -10px;
}
.resize-drag {
  background: rgba(0, 0, 0, 0);
  border: 4px solid #7c8383;
  color: red;
  font-size: 1px;
  font-family: sans-serif;
  border-radius: 8px;
  /* This makes things *much* easier */
  box-sizing: border-box;
}
.resize-container {
  width: 100%;
  height: 100%;
}
