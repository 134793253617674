.gramene-sidebar {
    position: fixed;
    background-color: #557b74;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    width: 250px;
}
.sorghumbase-sidebar {
    position: fixed;
    background-color: #a03e34;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    width: 250px;
}
.gramene-auth-container {
    background-color: #fff3a9;
    padding-left: 4px;
    padding-bottom: 4px;
}
.sorghumbase-auth-container {
    background-color: #fff3a9;
    padding-left: 4px;
    padding-bottom: 4px;
}
.gramene-filter-container {
    background-color: #fff3a9;
    padding-left: 4px;
    padding-bottom: 4px;
}
.sorghumbase-filter-container {
    background-color: #fff3a9;
    padding-left: 4px;
    padding-bottom: 4px;
}
.gramene-filter {
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    background-color: white;
    font-size: small;
}
.gramene-filter span:hover {
    font-weight:bolder;
}
.gramene-filter-AND {
    background-color: #ffcd82;
}
.gramene-filter-OR {
    background-color: #c5eeb9;
}
.gramene-filter-NOT {
    border-left-color: #ff5354;
    border-left-style: inset;
    border-left-width: 4px;
    padding-left: 4px;
}
.gramene-filter-operation {
    text-align: center;
    font-style: italic;
}
.gramene-filter-marked {
    font-weight: bolder;
    /*background-color: #feff96;*/
}
.gramene-filter-menu ul {
    border-top-style: solid;
    border-top-width: 2px;
    padding-left: 0;
}
.gramene-filter-menu li {
    display: block;
    cursor: pointer;
    padding-left: 15px;
}
.gramene-filter-menu li:hover {
    background-color: #feff96;
}

.gramene-view-header {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    font-size: larger;
    font-weight: bold;
    background-color: #fff3a96e;
    border-top: solid;
    border-top-color: #557b74;
    color: #a03e34;
}

.gramene-view-container {
    background-color: #fff3a9;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
}
.gramene-view {
    padding-left: 0px;
    padding-right: 10px;
    margin-bottom: 0;
    background-color: white;
}
.gramene-view-span-on {
    padding-left: 5px;
    cursor: pointer;
    vertical-align: text-bottom;
    color: #a03e34;
}
.gramene-view-span-off {
    padding-left: 5px;
    cursor: pointer;
    vertical-align: text-bottom;
    color: darkgray;
}
.gramene-view li {
    display: block;
    cursor: pointer;
    padding-left: 5px;
    border-left-style: inset;
    border-left-width: 8px;
    margin-bottom: 2px;
}

.gramene-view-on {
    border-left-color: #40a0ff;
    font-weight: bold;
}
.gramene-view-off {
    border-left-color: #ff6d68;
    color: grey;
}
.gramene-view-disabled {
    color: darkgray;
    border-left-color: #efefef;
    cursor: default!important;
}
#gramene-suggestion button {
    border-radius: .6rem;
    line-height: 1;
}
#gramene-suggestion button:focus {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

