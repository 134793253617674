.display-mode .btn-toolbar {
  position: absolute;
}

.genetree-vis .node, .genetree-vis .edge {
  cursor: pointer;
}

.genetree-vis .tree-wrapper * {
  transition: all 20ms ease-in-out;
}

.genetree-vis .tree-wrapper .clade {
  transition: transform 20ms ease-in-out;
}

.genetree-vis .tree-wrapper .clade .edge-rect {
  transition: scaleY 20ms ease-in-out, scaleX 20ms ease-in-out, transform 20ms ease-in-out;
}

.genetree-vis .interaction-helper {
  opacity: 0;
}

.genetree-vis .node circle {
  stroke: #777;
  fill: #fff;
}

.genetree-vis .speciation rect {
  fill: navy;
}

.genetree-vis .duplication rect {
  fill: #cd0000;
}

.genetree-vis .gene_split rect {
  fill: #ff0;
  -webkit-animation: .2s linear 50 pulse;
  animation: .2s linear 50 pulse;
}

@keyframes pulse {
  0% {
    fill: #cd0000;
  }

  50% {
    fill: #ff0;
  }

  100% {
    fill: #00f;
  }
}

.genetree-vis .node:hover .internal, .genetree-vis .edge:hover .internal, .genetree-vis .node:hover .gene circle, .genetree-vis .edge:hover .gene circle, .genetree-vis .node:hover ~ .clade .node rect, .genetree-vis .edge:hover ~ .clade .node rect, .genetree-vis .node:hover + .node rect, .genetree-vis .edge:hover + .node rect {
  transform: scale(2);
}

.genetree-vis .node:hover ~ .clade .interaction-rect, .genetree-vis .edge:hover ~ .clade .interaction-rect {
  opacity: 1;
  fill-opacity: 1;
}

.genetree-vis .node:hover ~ .clade circle, .genetree-vis .edge:hover ~ .clade circle, .genetree-vis .node:hover + .node circle, .genetree-vis .edge:hover + .node circle, .genetree-vis .node:hover .collapsed path, .genetree-vis .edge:hover .collapsed path, .genetree-vis .node:hover ~ .clade .collapsed path, .genetree-vis .edge:hover ~ .clade .collapsed path, .genetree-vis .node:hover + .node .collapsed path, .genetree-vis .edge:hover + .node .collapsed path {
  stroke-width: 2px;
}

.genetree-vis .node:hover ~ .clade text, .genetree-vis .edge:hover ~ .clade text, .genetree-vis .node:hover text, .genetree-vis .edge:hover text {
  font-weight: bolder;
}

.genetree-vis .node .gene.ortholog_one2one circle, .genetree-vis .node .gene.ortholog_one2many circle, .genetree-vis .node .gene.ortholog_many2many circle {
  stroke: orange;
}

.genetree-vis .node .gene.within_species_paralog circle {
  stroke: green;
}

.genetree-vis .node .gene.self circle {
  stroke: red;
  fill: red;
}

.genetree-vis .node .gene.self text {
  font-weight: bolder;
}

.genetree-vis .node .gene.representative circle {
  stroke: orange;
  fill: orange;
}

.genetree-vis .node text {
  fill-opacity: 1;
  text-anchor: start;
  font-size: 11px;
}

.genetree-vis .collapsed path {
  stroke-width: 1px;
}

.genetree-vis .domains rect {
  cursor: pointer;
  stroke-opacity: 0;
}

.genetree-vis .domains rect:hover {
  cursor: pointer;
  stroke-opacity: 1;
}

.genetree-vis .genetree-vis .node, .genetree-vis .genetree-vis .edge {
  cursor: pointer;
}

.genetree-vis .genetree-vis .tree-wrapper * {
  transition: all 20ms ease-in-out;
}

.genetree-vis .genetree-vis .tree-wrapper .clade {
  transition: transform 20ms ease-in-out;
}

.genetree-vis .genetree-vis .tree-wrapper .clade .edge-rect {
  transition: scaleY 20ms ease-in-out, scaleX 20ms ease-in-out, transform 20ms ease-in-out;
}

.genetree-vis .genetree-vis .interaction-helper {
  opacity: 0;
}

.genetree-vis .genetree-vis .node circle {
  stroke: #777;
  fill: #fff;
}

.genetree-vis .genetree-vis .speciation rect {
  fill: navy;
}

.genetree-vis .genetree-vis .duplication rect {
  fill: #cd0000;
}

.genetree-vis .genetree-vis .node:hover .internal, .genetree-vis .genetree-vis .edge:hover .internal, .genetree-vis .genetree-vis .node:hover .gene circle, .genetree-vis .genetree-vis .edge:hover .gene circle, .genetree-vis .genetree-vis .node:hover ~ .clade .node rect, .genetree-vis .genetree-vis .edge:hover ~ .clade .node rect, .genetree-vis .genetree-vis .node:hover + .node rect, .genetree-vis .genetree-vis .edge:hover + .node rect {
  transform: scale(2);
}

.genetree-vis .genetree-vis .node:hover ~ .clade .interaction-rect, .genetree-vis .genetree-vis .edge:hover ~ .clade .interaction-rect {
  opacity: 1;
  fill-opacity: 1;
}

.genetree-vis .genetree-vis .node:hover ~ .clade circle, .genetree-vis .genetree-vis .edge:hover ~ .clade circle, .genetree-vis .genetree-vis .node:hover + .node circle, .genetree-vis .genetree-vis .edge:hover + .node circle, .genetree-vis .genetree-vis .node:hover .collapsed path, .genetree-vis .genetree-vis .edge:hover .collapsed path, .genetree-vis .genetree-vis .node:hover ~ .clade .collapsed path, .genetree-vis .genetree-vis .edge:hover ~ .clade .collapsed path, .genetree-vis .genetree-vis .node:hover + .node .collapsed path, .genetree-vis .genetree-vis .edge:hover + .node .collapsed path {
  stroke-width: 2px;
}

.genetree-vis .genetree-vis .node:hover ~ .clade text, .genetree-vis .genetree-vis .edge:hover ~ .clade text, .genetree-vis .genetree-vis .node:hover text, .genetree-vis .genetree-vis .edge:hover text {
  font-weight: bolder;
}

.genetree-vis .genetree-vis .node .gene.ortholog_one2one circle, .genetree-vis .genetree-vis .node .gene.ortholog_one2many circle, .genetree-vis .genetree-vis .node .gene.ortholog_many2many circle {
  stroke: orange;
}

.genetree-vis .genetree-vis .node .gene.within_species_paralog circle {
  stroke: green;
}

.genetree-vis .genetree-vis .node .gene.self circle {
  stroke: red;
  fill: red;
}

.genetree-vis .genetree-vis .node .gene.self text {
  font-weight: bolder;
}

.genetree-vis .genetree-vis .node .gene.representative circle {
  stroke: orange;
  fill: orange;
}

.genetree-vis .genetree-vis .node text {
  fill-opacity: 1;
  text-anchor: start;
  font-size: 11px;
}

.genetree-vis .genetree-vis .collapsed path {
  stroke-width: 1px;
}

.genetree-vis .genetree-vis .domains rect {
  cursor: pointer;
  stroke-opacity: 0;
}

.genetree-vis .genetree-vis .domains rect:hover {
  cursor: pointer;
  stroke-opacity: 1;
}

.genetree-vis .genetree-vis .tooltip-title-button {
  margin-top: -4px;
}

.popover-title .tooltip-title-button {
  margin-top: -4px !important;
  margin-right: 4px !important;
}

.msa-slider {
  margin-bottom: -10px;
  padding-top: 10px;
  padding-bottom: 0;
  position: relative;
}

.resize-drag {
  color: red;
  box-sizing: border-box;
  background: none;
  border: 4px solid #7c8383;
  border-radius: 8px;
  font-family: sans-serif;
  font-size: 1px;
}

.resize-container {
  width: 100%;
  height: 100%;
}

.MSAlignments-wrapper {
  overflow: scroll hidden;
}

.clustal {
  white-space: nowrap;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.clustal .A, .clustal .I, .clustal .L, .clustal .M, .clustal .F, .clustal .W, .clustal .V {
  background-color: #80a0f0;
}

.clustal .R, .clustal .K {
  color: #fcff89;
  background-color: #f01505;
}

.clustal .N, .clustal .Q, .clustal .S, .clustal .T {
  background-color: #0f0;
}

.clustal .D, .clustal .E {
  color: #fcff89;
  background-color: #c048c0;
}

.clustal .C {
  background-color: #f08080;
}

.clustal .G {
  background-color: #f09048;
}

.clustal .H {
  background-color: #15a4a4;
}

.clustal .P {
  background-color: #ff0;
}

.clustal .B, .clustal .X, .clustal .Z {
  background-color: #fff;
}

.clustal .gap {
  color: #ccc;
}

.zappo {
  white-space: nowrap;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.zappo .A, .zappo .I, .zappo .L, .zappo .M, .zappo .V {
  background-color: #ffafaf;
}

.zappo .R, .zappo .H, .zappo .K {
  color: #fcff89;
  background-color: #6464ff;
}

.zappo .N, .zappo .Q, .zappo .S, .zappo .T {
  background-color: #0f0;
}

.zappo .D, .zappo .E {
  background-color: red;
}

.zappo .C {
  background-color: #ff0;
}

.zappo .G, .zappo .P {
  color: #fcff89;
  background-color: #f0f;
}

.zappo .F, .zappo .W, .zappo .Y {
  background-color: #ffc800;
}

.zappo .B, .zappo .X, .zappo .Z {
  background-color: #fff;
}

.zappo .gap {
  color: #ccc;
}

.taylor {
  white-space: nowrap;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.taylor .A {
  background-color: #cf0;
}

.taylor .R {
  color: #fcff89;
  background-color: #00f;
}

.taylor .N {
  color: #fcff89;
  background-color: #c0f;
}

.taylor .D {
  color: #fcff89;
  background-color: red;
}

.taylor .C {
  background-color: #ff0;
}

.taylor .Q {
  background-color: #f0c;
}

.taylor .E {
  color: #fcff89;
  background-color: #f06;
}

.taylor .G {
  background-color: #f90;
}

.taylor .H {
  color: #fcff89;
  background-color: #06f;
}

.taylor .I {
  background-color: #6f0;
}

.taylor .L {
  background-color: #3f0;
}

.taylor .K {
  color: #fcff89;
  background-color: #60f;
}

.taylor .M {
  background-color: #0f0;
}

.taylor .F {
  background-color: #0f6;
}

.taylor .P {
  background-color: #fc0;
}

.taylor .S {
  color: #fcff89;
  background-color: #f30;
}

.taylor .T {
  background-color: #f60;
}

.taylor .W {
  background-color: #0cf;
}

.taylor .Y {
  background-color: #0fc;
}

.taylor .V {
  background-color: #9f0;
}

.taylor .B, .taylor .X, .taylor .Z {
  background-color: #fff;
}

.taylor .gap {
  color: #ccc;
}

.hydrophobicity {
  white-space: nowrap;
  color: #fcff89;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.hydrophobicity .A {
  background-color: #ad0052;
}

.hydrophobicity .R, .hydrophobicity .K {
  background-color: #00f;
}

.hydrophobicity .N, .hydrophobicity .D, .hydrophobicity .Q, .hydrophobicity .E, .hydrophobicity .B, .hydrophobicity .Z {
  background-color: #0c00f3;
}

.hydrophobicity .C {
  background-color: #c2003d;
}

.hydrophobicity .G {
  background-color: #6a0095;
}

.hydrophobicity .H {
  background-color: #1500ea;
}

.hydrophobicity .L {
  background-color: #ea0015;
}

.hydrophobicity .M {
  background-color: #b0004f;
}

.hydrophobicity .F {
  background-color: #cb0034;
}

.hydrophobicity .P {
  background-color: #4600b9;
}

.hydrophobicity .S {
  background-color: #5e00a1;
}

.hydrophobicity .T {
  background-color: #61009e;
}

.hydrophobicity .W {
  background-color: #5b00a4;
}

.hydrophobicity .Y {
  background-color: #4f00b0;
}

.hydrophobicity .V {
  background-color: #f60009;
}

.hydrophobicity .X {
  background-color: #680097;
}

.hydrophobicity .gap {
  color: #ccc;
}

.helix_propensity {
  white-space: nowrap;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.helix_propensity .A {
  background-color: #e718e7;
}

.helix_propensity .R {
  background-color: #6f906f;
}

.helix_propensity .N {
  background-color: #1be41b;
}

.helix_propensity .D {
  background-color: #787;
}

.helix_propensity .C {
  background-color: #23dc23;
}

.helix_propensity .Q {
  background-color: #926d92;
}

.helix_propensity .E {
  background-color: #f0f;
}

.helix_propensity .G {
  background-color: #0f0;
}

.helix_propensity .H {
  background-color: #758a75;
}

.helix_propensity .I {
  background-color: #8a758a;
}

.helix_propensity .L {
  background-color: #ae51ae;
}

.helix_propensity .K {
  background-color: #a05fa0;
}

.helix_propensity .M {
  background-color: #ef10ef;
}

.helix_propensity .F {
  background-color: #986798;
}

.helix_propensity .P {
  background-color: #0f0;
}

.helix_propensity .S {
  background-color: #36c936;
}

.helix_propensity .T {
  background-color: #47b847;
}

.helix_propensity .W {
  background-color: #8a758a;
}

.helix_propensity .Y {
  background-color: #21de21;
}

.helix_propensity .V {
  background-color: #857a85;
}

.helix_propensity .B {
  background-color: #49b649;
}

.helix_propensity .X {
  background-color: #758a75;
}

.helix_propensity .Z {
  background-color: #c936c9;
}

.helix_propensity .gap {
  color: #ccc;
}

.strand_propensity {
  white-space: nowrap;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.strand_propensity .A {
  background-color: #5858a7;
}

.strand_propensity .R {
  background-color: #6b6b94;
}

.strand_propensity .N {
  background-color: #64649b;
}

.strand_propensity .D {
  background-color: #2121de;
}

.strand_propensity .C {
  background-color: #9d9d62;
}

.strand_propensity .Q {
  background-color: #8c8c73;
}

.strand_propensity .E {
  background-color: #00f;
}

.strand_propensity .G {
  background-color: #4949b6;
}

.strand_propensity .H {
  background-color: #60609f;
}

.strand_propensity .I {
  background-color: #ecec13;
}

.strand_propensity .L {
  background-color: #b2b24d;
}

.strand_propensity .K {
  background-color: #4747b8;
}

.strand_propensity .M {
  background-color: #82827d;
}

.strand_propensity .F {
  background-color: #c2c23d;
}

.strand_propensity .P {
  background-color: #2323dc;
}

.strand_propensity .S {
  background-color: #4949b6;
}

.strand_propensity .T {
  background-color: #9d9d62;
}

.strand_propensity .W {
  background-color: #c0c03f;
}

.strand_propensity .Y {
  background-color: #d3d32c;
}

.strand_propensity .V {
  background-color: #ff0;
}

.strand_propensity .B {
  background-color: #4343bc;
}

.strand_propensity .X {
  background-color: #797986;
}

.strand_propensity .Z {
  background-color: #4747b8;
}

.strand_propensity .A, .strand_propensity .R, .strand_propensity .N, .strand_propensity .D, .strand_propensity .E, .strand_propensity .G, .strand_propensity .H, .strand_propensity .K, .strand_propensity .P, .strand_propensity .S, .strand_propensity .B, .strand_propensity .Z {
  color: #fcff89;
}

.strand_propensity .gap {
  color: #ccc;
}

.turn_propensity {
  white-space: nowrap;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.turn_propensity .gap {
  color: #ccc;
}

.turn_propensity .A {
  background-color: #2cd3d3;
}

.turn_propensity .R {
  background-color: #708f8f;
}

.turn_propensity .N {
  background-color: red;
}

.turn_propensity .D {
  background-color: #e81717;
}

.turn_propensity .C {
  background-color: #a85757;
}

.turn_propensity .Q {
  background-color: #3fc0c0;
}

.turn_propensity .E {
  background-color: #788;
}

.turn_propensity .G {
  background-color: red;
}

.turn_propensity .H {
  background-color: #708f8f;
}

.turn_propensity .I {
  background-color: #0ff;
}

.turn_propensity .L {
  background-color: #1ce3e3;
}

.turn_propensity .K {
  background-color: #7e8181;
}

.turn_propensity .M, .turn_propensity .F {
  background-color: #1ee1e1;
}

.turn_propensity .P {
  background-color: #f60909;
}

.turn_propensity .S {
  background-color: #e11e1e;
}

.turn_propensity .T, .turn_propensity .W {
  background-color: #738c8c;
}

.turn_propensity .Y {
  background-color: #9d6262;
}

.turn_propensity .V {
  background-color: #07f8f8;
}

.turn_propensity .B {
  background-color: #f30c0c;
}

.turn_propensity .X {
  background-color: #7c8383;
}

.turn_propensity .Z {
  background-color: #5ba4a4;
}

.turn_propensity .N, .turn_propensity .D, .turn_propensity .G, .turn_propensity .P, .turn_propensity .S, .turn_propensity .B {
  color: #fcff89;
}

.buried_index {
  white-space: nowrap;
  height: 18px;
  font-family: monospace;
  font-size: 12px;
}

.buried_index .gap {
  color: #ccc;
}

.buried_index .A {
  background-color: #00a35c;
}

.buried_index .R {
  background-color: #00fc03;
}

.buried_index .N, .buried_index .D {
  background-color: #00eb14;
}

.buried_index .C {
  background-color: #00f;
}

.buried_index .Q, .buried_index .E {
  background-color: #00f10e;
}

.buried_index .G {
  background-color: #009d62;
}

.buried_index .H {
  background-color: #00d52a;
}

.buried_index .I {
  background-color: #0054ab;
}

.buried_index .L {
  background-color: #007b84;
}

.buried_index .K {
  background-color: #0f0;
}

.buried_index .M {
  background-color: #009768;
}

.buried_index .F {
  background-color: #008778;
}

.buried_index .P {
  background-color: #00e01f;
}

.buried_index .S {
  background-color: #00d52a;
}

.buried_index .T {
  background-color: #00db24;
}

.buried_index .W {
  background-color: #00a857;
}

.buried_index .Y {
  background-color: #00e619;
}

.buried_index .V {
  background-color: #005fa0;
}

.buried_index .B {
  background-color: #00eb14;
}

.buried_index .X {
  background-color: #00b649;
}

.buried_index .Z {
  background-color: #00f10e;
}

.buried_index .C, .buried_index .I, .buried_index .V {
  color: #fcff89;
}
