/*

<classNamePrefix>-root

The outer container

*/
.tree-view-root {
}

/*

<classNamePrefix>-root

The outer container for a single node

*/
.tree-view-node {
    margin-left: 10px;
}


/*

<classNamePrefix>-node-collapse-toggle.collapse-spacer

Used to provide spacing when a parent node has no children

*/
.tree-view-node-collapse-toggle.collapse-spacer {
    width: 14px;
    display: inline-block;
}

/*
<classNamePrefix>-node-collapse-toggle.<expandIconClass>

The class to use for the icon that expands a collapsed parent node

*/


/*

<classNamePrefix>-node-collapse-toggle.<collapseIconClass>

The class to use for the icon that collapses an expanded parent node

*/

.tree-view-node-collapse-toggle {
    margin-right: 3px;
    width: 14px;
}

/*

<classNamePrefix>-node-label

*/
.tree-view-node-label {
    font-size: smaller;
    margin-left: 3px;
    padding: 1px;
    border: 1px solid #fff;
}

/*

<classNamePrefix>-node-label.selected

*/
.tree-view-node-label.selected {
    border: 1px solid #003f81;
    border-radius: 3px;
    background-color: #808080;
}

/*

<classNamePrefix>-node-checkbox

The class for a node checkbox

*/

.tree-view-node-checkbox {}

/*

<classNamePrefix>-node-children

The class for the element that wraps a nodes children (if any exist)

*/

.tree-view-node-children {}

