.MSAlignments-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
}
.clustal {
  white-space: nowrap;
  font-family: monospace;
  height: 18px;
  font-size: 12px;
}
.clustal .A,
.clustal .I,
.clustal .L,
.clustal .M,
.clustal .F,
.clustal .W,
.clustal .V {
  background-color: #80a0f0;
}
.clustal .R,
.clustal .K {
  background-color: #f01505;
  color: #fcff89;
}
.clustal .N,
.clustal .Q,
.clustal .S,
.clustal .T {
  background-color: #00ff00;
}
.clustal .D,
.clustal .E {
  background-color: #c048c0;
  color: #fcff89;
}
.clustal .C {
  background-color: #f08080;
}
.clustal .G {
  background-color: #f09048;
}
.clustal .H {
  background-color: #15a4a4;
}
.clustal .P {
  background-color: #ffff00;
}
.clustal .B,
.clustal .X,
.clustal .Z {
  background-color: #ffffff;
}
.clustal .gap {
  color: #ccc;
}
.zappo {
  white-space: nowrap;
  font-family: monospace;
  height: 18px;
  font-size: 12px;
}
.zappo .A,
.zappo .I,
.zappo .L,
.zappo .M,
.zappo .V {
  background-color: #ffafaf;
}
.zappo .R,
.zappo .H,
.zappo .K {
  background-color: #6464ff;
  color: #fcff89;
}
.zappo .N,
.zappo .Q,
.zappo .S,
.zappo .T {
  background-color: #00ff00;
}
.zappo .D,
.zappo .E {
  background-color: #ff0000;
}
.zappo .C {
  background-color: #ffff00;
}
.zappo .G,
.zappo .P {
  background-color: #ff00ff;
  color: #fcff89;
}
.zappo .F,
.zappo .W,
.zappo .Y {
  background-color: #ffc800;
}
.zappo .B,
.zappo .X,
.zappo .Z {
  background-color: #ffffff;
}
.zappo .gap {
  color: #ccc;
}
.taylor {
  white-space: nowrap;
  font-family: monospace;
  height: 18px;
  font-size: 12px;
}
.taylor .A {
  background-color: #ccff00;
}
.taylor .R {
  background-color: #0000ff;
  color: #fcff89;
}
.taylor .N {
  background-color: #cc00ff;
  color: #fcff89;
}
.taylor .D {
  background-color: #ff0000;
  color: #fcff89;
}
.taylor .C {
  background-color: #ffff00;
}
.taylor .Q {
  background-color: #ff00cc;
}
.taylor .E {
  background-color: #ff0066;
  color: #fcff89;
}
.taylor .G {
  background-color: #ff9900;
}
.taylor .H {
  background-color: #0066ff;
  color: #fcff89;
}
.taylor .I {
  background-color: #66ff00;
}
.taylor .L {
  background-color: #33ff00;
}
.taylor .K {
  background-color: #6600ff;
  color: #fcff89;
}
.taylor .M {
  background-color: #00ff00;
}
.taylor .F {
  background-color: #00ff66;
}
.taylor .P {
  background-color: #ffcc00;
}
.taylor .S {
  background-color: #ff3300;
  color: #fcff89;
}
.taylor .T {
  background-color: #ff6600;
}
.taylor .W {
  background-color: #00ccff;
}
.taylor .Y {
  background-color: #00ffcc;
}
.taylor .V {
  background-color: #99ff00;
}
.taylor .B,
.taylor .X,
.taylor .Z {
  background-color: #ffffff;
}
.taylor .gap {
  color: #ccc;
}
.hydrophobicity {
  white-space: nowrap;
  font-family: monospace;
  height: 18px;
  font-size: 12px;
  color: #fcff89;
}
.hydrophobicity .A {
  background-color: #ad0052;
}
.hydrophobicity .R,
.hydrophobicity .K {
  background-color: #0000ff;
}
.hydrophobicity .N,
.hydrophobicity .D,
.hydrophobicity .Q,
.hydrophobicity .E,
.hydrophobicity .B,
.hydrophobicity .Z {
  background-color: #0c00f3;
}
.hydrophobicity .C {
  background-color: #c2003d;
}
.hydrophobicity .G {
  background-color: #6a0095;
}
.hydrophobicity .H {
  background-color: #1500ea;
}
.hydrophobicity .L {
  background-color: #ea0015;
}
.hydrophobicity .M {
  background-color: #b0004f;
}
.hydrophobicity .F {
  background-color: #cb0034;
}
.hydrophobicity .P {
  background-color: #4600b9;
}
.hydrophobicity .S {
  background-color: #5e00a1;
}
.hydrophobicity .T {
  background-color: #61009e;
}
.hydrophobicity .W {
  background-color: #5b00a4;
}
.hydrophobicity .Y {
  background-color: #4f00b0;
}
.hydrophobicity .V {
  background-color: #f60009;
}
.hydrophobicity .X {
  background-color: #680097;
}
.hydrophobicity .gap {
  color: #ccc;
}
.helix_propensity {
  white-space: nowrap;
  font-family: monospace;
  height: 18px;
  font-size: 12px;
}
.helix_propensity .A {
  background-color: #e718e7;
}
.helix_propensity .R {
  background-color: #6f906f;
}
.helix_propensity .N {
  background-color: #1be41b;
}
.helix_propensity .D {
  background-color: #778877;
}
.helix_propensity .C {
  background-color: #23dc23;
}
.helix_propensity .Q {
  background-color: #926d92;
}
.helix_propensity .E {
  background-color: #ff00ff;
}
.helix_propensity .G {
  background-color: #00ff00;
}
.helix_propensity .H {
  background-color: #758a75;
}
.helix_propensity .I {
  background-color: #8a758a;
}
.helix_propensity .L {
  background-color: #ae51ae;
}
.helix_propensity .K {
  background-color: #a05fa0;
}
.helix_propensity .M {
  background-color: #ef10ef;
}
.helix_propensity .F {
  background-color: #986798;
}
.helix_propensity .P {
  background-color: #00ff00;
}
.helix_propensity .S {
  background-color: #36c936;
}
.helix_propensity .T {
  background-color: #47b847;
}
.helix_propensity .W {
  background-color: #8a758a;
}
.helix_propensity .Y {
  background-color: #21de21;
}
.helix_propensity .V {
  background-color: #857a85;
}
.helix_propensity .B {
  background-color: #49b649;
}
.helix_propensity .X {
  background-color: #758a75;
}
.helix_propensity .Z {
  background-color: #c936c9;
}
.helix_propensity .gap {
  color: #ccc;
}
.strand_propensity {
  white-space: nowrap;
  font-family: monospace;
  height: 18px;
  font-size: 12px;
}
.strand_propensity .A {
  background-color: #5858a7;
}
.strand_propensity .R {
  background-color: #6b6b94;
}
.strand_propensity .N {
  background-color: #64649b;
}
.strand_propensity .D {
  background-color: #2121de;
}
.strand_propensity .C {
  background-color: #9d9d62;
}
.strand_propensity .Q {
  background-color: #8c8c73;
}
.strand_propensity .E {
  background-color: #0000ff;
}
.strand_propensity .G {
  background-color: #4949b6;
}
.strand_propensity .H {
  background-color: #60609f;
}
.strand_propensity .I {
  background-color: #ecec13;
}
.strand_propensity .L {
  background-color: #b2b24d;
}
.strand_propensity .K {
  background-color: #4747b8;
}
.strand_propensity .M {
  background-color: #82827d;
}
.strand_propensity .F {
  background-color: #c2c23d;
}
.strand_propensity .P {
  background-color: #2323dc;
}
.strand_propensity .S {
  background-color: #4949b6;
}
.strand_propensity .T {
  background-color: #9d9d62;
}
.strand_propensity .W {
  background-color: #c0c03f;
}
.strand_propensity .Y {
  background-color: #d3d32c;
}
.strand_propensity .V {
  background-color: #ffff00;
}
.strand_propensity .B {
  background-color: #4343bc;
}
.strand_propensity .X {
  background-color: #797986;
}
.strand_propensity .Z {
  background-color: #4747b8;
}
.strand_propensity .A,
.strand_propensity .R,
.strand_propensity .N,
.strand_propensity .D,
.strand_propensity .E,
.strand_propensity .G,
.strand_propensity .H,
.strand_propensity .K,
.strand_propensity .P,
.strand_propensity .S,
.strand_propensity .B,
.strand_propensity .Z {
  color: #fcff89;
}
.strand_propensity .gap {
  color: #ccc;
}
.turn_propensity {
  white-space: nowrap;
  font-family: monospace;
  height: 18px;
  font-size: 12px;
}
.turn_propensity .gap {
  color: #ccc;
}
.turn_propensity .A {
  background-color: #2cd3d3;
}
.turn_propensity .R {
  background-color: #708f8f;
}
.turn_propensity .N {
  background-color: #ff0000;
}
.turn_propensity .D {
  background-color: #e81717;
}
.turn_propensity .C {
  background-color: #a85757;
}
.turn_propensity .Q {
  background-color: #3fc0c0;
}
.turn_propensity .E {
  background-color: #778888;
}
.turn_propensity .G {
  background-color: #ff0000;
}
.turn_propensity .H {
  background-color: #708f8f;
}
.turn_propensity .I {
  background-color: #00ffff;
}
.turn_propensity .L {
  background-color: #1ce3e3;
}
.turn_propensity .K {
  background-color: #7e8181;
}
.turn_propensity .M {
  background-color: #1ee1e1;
}
.turn_propensity .F {
  background-color: #1ee1e1;
}
.turn_propensity .P {
  background-color: #f60909;
}
.turn_propensity .S {
  background-color: #e11e1e;
}
.turn_propensity .T {
  background-color: #738c8c;
}
.turn_propensity .W {
  background-color: #738c8c;
}
.turn_propensity .Y {
  background-color: #9d6262;
}
.turn_propensity .V {
  background-color: #07f8f8;
}
.turn_propensity .B {
  background-color: #f30c0c;
}
.turn_propensity .X {
  background-color: #7c8383;
}
.turn_propensity .Z {
  background-color: #5ba4a4;
}
.turn_propensity .N,
.turn_propensity .D,
.turn_propensity .G,
.turn_propensity .P,
.turn_propensity .S,
.turn_propensity .B {
  color: #fcff89;
}
.buried_index {
  white-space: nowrap;
  font-family: monospace;
  height: 18px;
  font-size: 12px;
}
.buried_index .gap {
  color: #ccc;
}
.buried_index .A {
  background-color: #00a35c;
}
.buried_index .R {
  background-color: #00fc03;
}
.buried_index .N {
  background-color: #00eb14;
}
.buried_index .D {
  background-color: #00eb14;
}
.buried_index .C {
  background-color: #0000ff;
}
.buried_index .Q {
  background-color: #00f10e;
}
.buried_index .E {
  background-color: #00f10e;
}
.buried_index .G {
  background-color: #009d62;
}
.buried_index .H {
  background-color: #00d52a;
}
.buried_index .I {
  background-color: #0054ab;
}
.buried_index .L {
  background-color: #007b84;
}
.buried_index .K {
  background-color: #00ff00;
}
.buried_index .M {
  background-color: #009768;
}
.buried_index .F {
  background-color: #008778;
}
.buried_index .P {
  background-color: #00e01f;
}
.buried_index .S {
  background-color: #00d52a;
}
.buried_index .T {
  background-color: #00db24;
}
.buried_index .W {
  background-color: #00a857;
}
.buried_index .Y {
  background-color: #00e619;
}
.buried_index .V {
  background-color: #005fa0;
}
.buried_index .B {
  background-color: #00eb14;
}
.buried_index .X {
  background-color: #00b649;
}
.buried_index .Z {
  background-color: #00f10e;
}
.buried_index .C,
.buried_index .I,
.buried_index .V {
  color: #fcff89;
}
